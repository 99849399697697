import { PlainMessage, Timestamp } from "@bufbuild/protobuf";
import {
  useMutationStopDistributedCoupon,
  useMutationUpdateDistributedCoupon,
  useQueryGetDistributedCouponsByShopID,
} from "../../hooks/useQuery";
import { useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { dayjsToTimestamp, formatTimestamp } from "../../util/date";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../molecules/toast";
import { jaJP } from "@mui/x-date-pickers/locales";
import { GetDistributedCouponsByShopIDResponse } from "schema/gen/es/foodlosscoupon/shopconsole/v1/shopconsole_pb";

const DistributedCouponListPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1); // 現在のページ番号
  const limit = 20; // 1ページに表示する件数
  const [isShowFinishedCoupons, setIsShowFinishedCoupons] = useState<boolean>(
    () => {
      const savedValue = localStorage.getItem("isShowFinishedCoupons");
      return savedValue ? JSON.parse(savedValue) : false;
    }
  );
  const { data: getDistributedCouponsByShopID, refetch } =
    useQueryGetDistributedCouponsByShopID(
      currentPage,
      limit,
      isShowFinishedCoupons
    );
  const updateDistributedCoupon = useMutationUpdateDistributedCoupon();
  const stopDistributedCoupon = useMutationStopDistributedCoupon();
  const [isMenuOpen, setIsMenuOpen] = useState<number | null>(null); // メニューの開閉状態
  const [menuPosition, setMenuPosition] = useState<{
    top: number;
    left: number;
  }>({ top: 0, left: 0 }); // ポップアップの位置
  const buttonRefs = useRef<(HTMLDivElement | null)[]>([]); // 各ボタンの参照
  const menuRef = useRef<HTMLDivElement | null>(null); // メニューの参照
  const [isCouponSettingModalOpen, setIsCouponSettingModalOpen] =
    useState(false);
  const [isStartDateTimeFormOpen, setIsStartDateTimeFormOpen] = useState(false);
  const [isEndDateTimeFormOpen, setIsEndDateTimeFormOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState<Dayjs | null>(null);
  const [endDateTime, setEndDateTime] = useState<Dayjs | null>(null);
  const [remarks, setRemarks] = useState("");
  const [selectedCouponId, setSelectedCouponId] = useState("");
  const [isStopModalOpen, setIsStopModalOpen] = useState(false);
  const [distributedCouponID, setDistributedCouponID] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [endDateTimeError, setEndDateTimeError] = useState("");

  const toggleFinishedCoupons = () => {
    setIsShowFinishedCoupons((prevState) => !prevState);
  };

  const toggleMenu = (index: number) => {
    if (buttonRefs.current[index]) {
      const rect = buttonRefs.current[index]?.getBoundingClientRect();
      setMenuPosition({
        top: (rect?.bottom ?? 0) + window.scrollY, // スクロール位置を加味
        left: (rect?.left ?? 0) + window.scrollX, // スクロール位置を加味
      });
    }
    setIsMenuOpen((prev) => (prev === index ? null : index)); // クリックされた行のメニューを開く・閉じる
  };

  const nextPage = () => {
    if (
      getDistributedCouponsByShopID &&
      getDistributedCouponsByShopID.total > currentPage * limit
    ) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  // ページを戻す処理
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const openCouponSettingModal = (id: string) => {
    setSelectedCouponId(id);
    setIsCouponSettingModalOpen(true);
  };

  const closeCouponSettingModal = () => {
    setIsStartDateTimeFormOpen(false);
    setIsEndDateTimeFormOpen(false);
    setIsCouponSettingModalOpen(false);
    setStartDateTime(null);
    setEndDateTime(null);
    setRemarks("");
    setSelectedCouponId("");
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).id === "modal-overlay") {
      closeCouponSettingModal();
    }
  };

  const handleStartDateTimeRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartDateTime(null);
    setIsStartDateTimeFormOpen(e.target.value === "withDate");

    // 配布開始日時を「設定しない」に戻した場合、エラーメッセージを消去
    if (e.target.value === "withoutDate") {
      setEndDateTimeError("");
    }
  };

  const handleEndDateTimeRadioChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEndDateTime(null);
    setIsEndDateTimeFormOpen(e.target.value === "withDate");

    // 配布終了日時を「設定しない」に戻した場合、エラーメッセージを消去
    if (e.target.value === "withoutDate") {
      setEndDateTimeError("");
    }
  };
  const handleStartDateTimeChange = (newValue: Dayjs | null) => {
    setStartDateTime(newValue);

    // 開始日時を変更した際にバリデーションを確認
    if (endDateTime && newValue && newValue.isAfter(endDateTime)) {
      setEndDateTimeError(
        "配布開始日時は配布終了日時より前に設定してください。"
      );
    } else {
      setEndDateTimeError("");
    }
  };

  const handleEndDateTimeChange = (newValue: Dayjs | null) => {
    setEndDateTime(newValue);

    // 終了日時が開始日時より前の場合のバリデーション
    if (startDateTime && newValue && newValue.isBefore(startDateTime)) {
      setEndDateTimeError(
        "配布終了日時は配布開始日時より後に設定してください。"
      );
    } else {
      setEndDateTimeError(""); // エラーがなければエラーメッセージをクリア
    }
  };

  const isOkSettingButtonDisabled = () => {
    if (
      isStartDateTimeFormOpen &&
      isEndDateTimeFormOpen &&
      (!startDateTime || !endDateTime)
    ) {
      return true;
    }
    if (isStartDateTimeFormOpen && !startDateTime) {
      return true;
    }
    if (isEndDateTimeFormOpen && !endDateTime) {
      return true;
    }
    if (endDateTimeError) {
      return true; // エラーがある場合はボタンを無効化
    }
    return false;
  };

  const openStopModal = (id: string) => {
    setSelectedCouponId(id);
    setIsStopModalOpen(true);
  };

  const handleUpdateSubmit = async () => {
    const parsedStartDateTime = startDateTime
      ? dayjsToTimestamp(startDateTime)
      : dayjsToTimestamp(dayjs());
    const parsedEndDateTime = dayjsToTimestamp(endDateTime);

    // バリデーションチェック
    if (!startDateTime && endDateTime && endDateTime.isBefore(dayjs())) {
      setEndDateTimeError(
        "配布開始日時を設定しない場合は配布終了日時は現在の時刻より後に設定してください。"
      );
      return; // バリデーションエラーがある場合は処理を中断
    }

    const updatedCoupon = await updateDistributedCoupon.mutateAsync({
      coupon: {
        id: selectedCouponId,
        distributionStartAt: parsedStartDateTime,
        distributionEndAt: parsedEndDateTime,
        remarks: remarks,
      },
    });
    closeCouponSettingModal();
    navigate("/distributed_coupons", {
      state: { distributedCouponID: updatedCoupon.coupon?.id },
    });
  };

  const handleStopSubmit = () => {
    stopDistributedCoupon.mutateAsync({
      id: selectedCouponId,
    });
    const deletedCouponId = selectedCouponId;
    setSelectedCouponId("");
    setIsStopModalOpen(false);
    navigate("/distributed_coupons", {
      state: { stoppedCouponID: deletedCouponId },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (location.state && location.state.distributedCouponID) {
      setDistributedCouponID(location.state.distributedCouponID);
      setToastMessage("クーポンを配布しました");
      setShowToast(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state && location.state.stoppedCouponID) {
      setDistributedCouponID(location.state.stoppedCouponID);
      setToastMessage("クーポン配布を停止しました");
      setShowToast(true);
    }
  }, [location.state]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        !buttonRefs.current.some((ref) => ref?.contains(event.target as Node))
      ) {
        setIsMenuOpen(null); // ポップアップを非表示に
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    refetch(); // currentPageやlimitが変わったらデータを再取得する
  }, [currentPage, limit, refetch]);

  useEffect(() => {
    refetch(); // isShowFinishedCouponsが変更されたらデータを再取得
  }, [isShowFinishedCoupons, refetch]);

  useEffect(() => {
    localStorage.setItem(
      "isShowFinishedCoupons",
      JSON.stringify(isShowFinishedCoupons)
    );
  }, [isShowFinishedCoupons]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sharedProps = {
    isShowFinishedCoupons,
    toggleFinishedCoupons,
    currentPage,
    nextPage,
    prevPage,
    limit,
    getDistributedCouponsByShopID,
    distributedCouponID,
    toggleMenu,
    isMenuOpen,
    menuPosition,
    buttonRefs,
    menuRef,
    openCouponSettingModal,
    openStopModal,
    handleOverlayClick,
    handleStartDateTimeRadioChange,
    handleEndDateTimeRadioChange,
    handleStartDateTimeChange,
    handleEndDateTimeChange,
    isOkSettingButtonDisabled,
    handleUpdateSubmit,
    handleStopSubmit,
    isCouponSettingModalOpen,
    toastMessage,
    isStopModalOpen,
    showToast,
    isStartDateTimeFormOpen,
    startDateTime,
    isEndDateTimeFormOpen,
    endDateTime,
    setShowToast,
    endDateTimeError,
    remarks,
    setRemarks,
    closeCouponSettingModal,
    setIsStopModalOpen,
    setSelectedCouponId,
  };

  return isMobile ? (
    <MobileLoginPage {...sharedProps} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
};

interface SharedProps {
  isShowFinishedCoupons: boolean;
  toggleFinishedCoupons: () => void;
  currentPage: number;
  nextPage: () => void;
  prevPage: () => void;
  limit: number;
  getDistributedCouponsByShopID:
    | PlainMessage<GetDistributedCouponsByShopIDResponse>
    | undefined;
  distributedCouponID: string;
  toggleMenu: (index: number) => void;
  isMenuOpen: number | null;
  menuPosition: { top: number; left: number };
  buttonRefs: React.MutableRefObject<(HTMLDivElement | null)[]>;
  menuRef: React.MutableRefObject<HTMLDivElement | null>;
  openCouponSettingModal: (id: string) => void;
  openStopModal: (id: string) => void;
  handleOverlayClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleStartDateTimeRadioChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleEndDateTimeRadioChange: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  handleStartDateTimeChange: (newValue: Dayjs | null) => void;
  handleEndDateTimeChange: (newValue: Dayjs | null) => void;
  isOkSettingButtonDisabled: () => boolean;
  handleUpdateSubmit: () => Promise<void>;
  handleStopSubmit: () => void;
  isCouponSettingModalOpen: boolean;
  toastMessage: string;
  isStopModalOpen: boolean;
  showToast: boolean;
  isStartDateTimeFormOpen: boolean;
  startDateTime: dayjs.Dayjs | null;
  isEndDateTimeFormOpen: boolean;
  endDateTime: dayjs.Dayjs | null;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  endDateTimeError: string;
  remarks: string;
  setRemarks: React.Dispatch<React.SetStateAction<string>>;
  closeCouponSettingModal: () => void;
  setIsStopModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCouponId: React.Dispatch<React.SetStateAction<string>>;
}

const MobileLoginPage: React.FC<SharedProps> = ({
  isShowFinishedCoupons,
  toggleFinishedCoupons,
  currentPage,
  nextPage,
  prevPage,
  limit,
  getDistributedCouponsByShopID,
  distributedCouponID,
  toggleMenu,
  isMenuOpen,
  menuPosition,
  buttonRefs,
  menuRef,
  openCouponSettingModal,
  openStopModal,
  handleOverlayClick,
  handleStartDateTimeRadioChange,
  handleEndDateTimeRadioChange,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  isOkSettingButtonDisabled,
  handleUpdateSubmit,
  handleStopSubmit,
  isCouponSettingModalOpen,
  toastMessage,
  isStopModalOpen,
  showToast,
  isStartDateTimeFormOpen,
  startDateTime,
  isEndDateTimeFormOpen,
  endDateTime,
  setShowToast,
  endDateTimeError,
  remarks,
  setRemarks,
  closeCouponSettingModal,
  setIsStopModalOpen,
  setSelectedCouponId,
}) => {
  return (
    <>
      <div className="p-[16px]">
        <div className="flex flex-col gap-16px">
          <h2 className="m-0 text-[20px] text-#111827 font-semibold leading-7 font-hiragino">
            クーポン配布状況
          </h2>
          <div className="flex gap-24px">
            <div className="text-14px font-hiragino leading-10">
              配布済クーポンを表示する
            </div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                checked={isShowFinishedCoupons}
                onChange={toggleFinishedCoupons}
                type="checkbox"
                value=""
                className="sr-only peer"
              />
              <div className="relative w-28px h-28px pl-2px pr-22px bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="mt-[16px] bg-white rounded-t-[16px] border-[1px] border-lightGray border-solid">
          <div className="flex justify-end h-[54px] px-[24px]">
            <div className="flex gap-[16px] w-[130px]">
              <p className="w-[62px] my-0 text-[#111827] text-[12px] leading-14">
                {currentPage}/
                {Math.ceil((getDistributedCouponsByShopID?.total || 1) / limit)}
                ページ
              </p>
              <div className="flex gap-[4px] pt-[15px]">
                <div
                  style={{
                    WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                    outline: "none", // タップ時のアウトラインを無効化
                  }}
                  onClick={prevPage}
                >
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_back
                  </span>
                </div>
                <div
                  style={{
                    WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                    outline: "none", // タップ時のアウトラインを無効化
                  }}
                  onClick={nextPage}
                >
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_forward
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white mt-4px rounded-b-[16px] border-x-[1px] border-x-lightGray border-x-solid">
          {getDistributedCouponsByShopID?.activeCoupons.map((coupon, index) => {
            return (
              <div
                key={index}
                className={`flex flex-col gap-12px h-[270px] p-[20px] gap-[12px] ${distributedCouponID == coupon.id && "bg-primaryLight"} border-b-[1px] border-b-lightGray border-b-solid ${index === getDistributedCouponsByShopID?.activeCoupons.length + getDistributedCouponsByShopID?.upcomingCoupons.length + getDistributedCouponsByShopID?.otherCoupons.length - 1 ? "rounded-b-[16px]" : ""}`}
              >
                <div>
                  <p className="w-[42px] h-[20px] m-0 px-[6px] py-[2px] bg-#EFF6FF border-[1px] border-#93C5FD border-solid rounded-[4px] text-[14px] text-#1D4ED8 font-hiragino font-medium leading-5">
                    配布中
                  </p>
                </div>
                <div className="flex justify-between h-42px">
                  <div className="text-12px text-secondary">商品画像</div>
                  <div>
                    {coupon.imageBase64 ? (
                      <img
                        src={coupon.imageBase64}
                        alt={coupon.couponName}
                        className="w-72px h-42px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                      />
                    ) : (
                      <p>image</p>
                    )}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">商品名</div>
                  <div className="text-12px text-#111827">
                    {coupon.couponName}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">割引</div>
                  <div className="text-12px text-#111827">
                    {coupon.discountMethod == "percentage_discount"
                      ? coupon.discountValue + "%OFF"
                      : coupon.discountValue + "円引き"}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">価格</div>
                  <div className="text-12px text-#111827">
                    {coupon.isSetOriginalPrice
                      ? coupon.discountMethod === "percentage_discount"
                        ? // パーセント割引の場合
                          `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                        : // 固定金額割引の場合
                          `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                      : "-"}
                    {coupon.isSetOriginalPrice &&
                      `(定価${Number(coupon.originalPrice)}円)`}
                  </div>
                </div>
                <div className="flex justify-between h-32px">
                  <div className="text-12px text-secondary">配布期間</div>
                  <div className="text-12px text-#111827">
                    {formatTimestamp(
                      coupon.distributionStartAt as Timestamp
                    ) === "" &&
                    formatTimestamp(coupon.distributionEndAt as Timestamp) ===
                      "" ? (
                      "-"
                    ) : (
                      <>
                        {formatTimestamp(
                          coupon.distributionStartAt as Timestamp
                        )}{" "}
                        〜
                        <br />
                        {formatTimestamp(coupon.distributionEndAt as Timestamp)}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">利用枚数</div>
                  <div className="text-12px text-#111827">
                    {Number(coupon.usageCount)}
                  </div>
                </div>
                <div className="flex justify-end h-34px">
                  <div
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    onClick={() => toggleMenu(index)}
                    ref={(el) => (buttonRefs.current[index] = el)} // ボタンの参照を設定
                    className="w-[24px]"
                  >
                    <span className="pt-5px material-symbols-rounded text-[24px]">
                      more_horiz
                    </span>
                  </div>
                </div>
                {isMenuOpen === index && (
                  <div
                    ref={menuRef}
                    style={{
                      position: "absolute",
                      top: `${menuPosition.top}px`,
                      left: `${menuPosition.left - 100}px`,
                    }}
                    className="z-10 w-[140px] h-[64px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                  >
                    <ul className="m-0 p-0">
                      <li
                        style={{
                          WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                          outline: "none", // タップ時のアウトラインを無効化
                        }}
                        onClick={() => openCouponSettingModal(coupon.id)}
                        className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <span className="material-symbols-rounded text-[20px]">
                          edit
                        </span>
                        <span className="w-[120px] h-[12px] text-#111827 font-hiragino leading-5">
                          配布設定を編集
                        </span>
                      </li>
                      <li
                        style={{
                          WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                          outline: "none", // タップ時のアウトラインを無効化
                        }}
                        onClick={() => openStopModal(coupon.id)}
                        className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <span className="material-symbols-rounded text-[20px] text-#B91C1C">
                          stop_circle
                        </span>
                        <span className="w-[120px] h-[12px] text-#B91C1C font-hiragino leading-5">
                          配布を中止
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
          {getDistributedCouponsByShopID?.upcomingCoupons.map(
            (coupon, index) => {
              const upcomingIndex =
                index + getDistributedCouponsByShopID.activeCoupons.length;
              return (
                <div
                  key={upcomingIndex}
                  className={`flex flex-col gap-12px h-[270px] p-[20px] gap-[12px] ${distributedCouponID == coupon.id && "bg-primaryLight"} border-b-[1px] border-b-lightGray border-b-solid ${index + getDistributedCouponsByShopID?.activeCoupons.length === getDistributedCouponsByShopID?.activeCoupons.length + getDistributedCouponsByShopID?.upcomingCoupons.length + getDistributedCouponsByShopID?.otherCoupons.length - 1 ? "rounded-b-[16px]" : ""}`}
                >
                  <div>
                    <p className="m-0 w-[42px] h-[20px] px-[6px] py-[2px] bg-#FFF7ED border-[1px] border-#FDBA74 border-solid rounded-[4px] text-[14px] text-#C2410C font-hiragino font-medium leading-5">
                      配布前
                    </p>
                  </div>
                  <div className="flex justify-between h-42px">
                    <div className="text-12px text-secondary">商品画像</div>
                    <div>
                      {coupon.imageBase64 ? (
                        <img
                          src={coupon.imageBase64}
                          alt={coupon.couponName}
                          className="w-72px h-42px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                        />
                      ) : (
                        <p>image</p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between h-16px">
                    <div className="text-12px text-secondary">商品名</div>
                    <div className="text-12px text-#111827">
                      {coupon.couponName}
                    </div>
                  </div>
                  <div className="flex justify-between h-16px">
                    <div className="text-12px text-secondary">割引</div>
                    <div className="text-12px text-#111827">
                      {coupon.discountMethod == "percentage_discount"
                        ? coupon.discountValue + "%OFF"
                        : coupon.discountValue + "円引き"}
                    </div>
                  </div>
                  <div className="flex justify-between h-16px">
                    <div className="text-12px text-secondary">価格</div>
                    <div className="text-12px text-#111827">
                      {coupon.isSetOriginalPrice
                        ? coupon.discountMethod === "percentage_discount"
                          ? // パーセント割引の場合
                            `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                          : // 固定金額割引の場合
                            `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                        : "-"}
                      {coupon.isSetOriginalPrice &&
                        `(定価${Number(coupon.originalPrice)}円)`}
                    </div>
                  </div>
                  <div className="flex justify-between h-32px">
                    <div className="text-12px text-secondary">配布期間</div>
                    <div className="text-12px text-#111827">
                      {formatTimestamp(
                        coupon.distributionStartAt as Timestamp
                      ) === "" &&
                      formatTimestamp(coupon.distributionEndAt as Timestamp) ===
                        "" ? (
                        "-"
                      ) : (
                        <>
                          {formatTimestamp(
                            coupon.distributionStartAt as Timestamp
                          )}{" "}
                          〜
                          <br />
                          {formatTimestamp(
                            coupon.distributionEndAt as Timestamp
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between h-16px">
                    <div className="text-12px text-secondary">利用枚数</div>
                    <div className="text-12px text-#111827">
                      {Number(coupon.usageCount)}
                    </div>
                  </div>
                  <div className="flex justify-end h-34px">
                    <div
                      onClick={() => toggleMenu(upcomingIndex)}
                      style={{
                        WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                        outline: "none", // タップ時のアウトラインを無効化
                      }}
                      ref={(el) => (buttonRefs.current[upcomingIndex] = el)} // ボタンの参照を設定
                      className="w-[24px]"
                    >
                      <span className="pt-5px material-symbols-rounded text-[24px]">
                        more_horiz
                      </span>
                    </div>
                  </div>
                  {isMenuOpen === upcomingIndex && (
                    <div
                      ref={menuRef}
                      style={{
                        position: "absolute",
                        top: `${menuPosition.top}px`,
                        left: `${menuPosition.left - 100}px`,
                      }}
                      className="z-10 w-[140px] h-[64px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                    >
                      <ul className="m-0 p-0">
                        <li
                          onClick={() => openCouponSettingModal(coupon.id)}
                          style={{
                            WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                            outline: "none", // タップ時のアウトラインを無効化
                          }}
                          className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                        >
                          <span className="material-symbols-rounded text-[20px]">
                            edit
                          </span>
                          <span className="w-[120px] h-[12px] text-#111827 font-hiragino leading-5">
                            配布設定を編集
                          </span>
                        </li>
                        <li
                          onClick={() => openStopModal(coupon.id)}
                          style={{
                            WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                            outline: "none", // タップ時のアウトラインを無効化
                          }}
                          className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                        >
                          <span className="material-symbols-rounded text-[20px] text-#B91C1C">
                            stop_circle
                          </span>
                          <span className="w-[120px] h-[12px] text-#B91C1C font-hiragino leading-5">
                            配布を中止
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              );
            }
          )}
          {getDistributedCouponsByShopID?.otherCoupons.map((coupon, index) => {
            return (
              <div
                key={index}
                className={`flex flex-col gap-12px h-[270px] p-[20px] gap-[12px] ${distributedCouponID == coupon.id && "bg-primaryLight"} border-b-[1px] border-b-lightGray border-b-solid ${index === getDistributedCouponsByShopID?.otherCoupons.length - 1 ? "rounded-b-[16px]" : ""}`}
              >
                <div>
                  <p className="m-0 w-[42px] h-[20px] px-[6px] py-[2px] bg-#F9FAFB border-[1px] border-#D1D5DB border-solid rounded-[4px] text-[14px] text-#374151 font-hiragino font-medium leading-5">
                    配布済
                  </p>
                </div>
                <div className="flex justify-between h-42px">
                  <div className="text-12px text-secondary">商品画像</div>
                  <div>
                    {coupon.imageBase64 ? (
                      <img
                        src={coupon.imageBase64}
                        alt={coupon.couponName}
                        className="w-72px h-42px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                      />
                    ) : (
                      <p>image</p>
                    )}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">商品名</div>
                  <div className="text-12px text-#111827">
                    {coupon.couponName}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">割引</div>
                  <div className="text-12px text-#111827">
                    {coupon.discountMethod == "percentage_discount"
                      ? coupon.discountValue + "%OFF"
                      : coupon.discountValue + "円引き"}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">価格</div>
                  <div className="text-12px text-#111827">
                    {coupon.isSetOriginalPrice
                      ? coupon.discountMethod === "percentage_discount"
                        ? // パーセント割引の場合
                          `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                        : // 固定金額割引の場合
                          `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                      : "-"}
                    {coupon.isSetOriginalPrice &&
                      `(定価${Number(coupon.originalPrice)}円)`}
                  </div>
                </div>
                <div className="flex justify-between h-32px">
                  <div className="text-12px text-secondary">配布期間</div>
                  <div className="text-12px text-#111827">
                    {formatTimestamp(
                      coupon.distributionStartAt as Timestamp
                    ) === "" &&
                    formatTimestamp(coupon.distributionEndAt as Timestamp) ===
                      "" ? (
                      "-"
                    ) : (
                      <>
                        {formatTimestamp(
                          coupon.distributionStartAt as Timestamp
                        )}{" "}
                        〜
                        <br />
                        {formatTimestamp(coupon.distributionEndAt as Timestamp)}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-between h-16px">
                  <div className="text-12px text-secondary">利用枚数</div>
                  <div className="text-12px text-#111827">
                    {Number(coupon.usageCount)}
                  </div>
                </div>
                <div className="flex justify-end h-34px">
                  <div
                    onClick={() => toggleMenu(index)}
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    ref={(el) => (buttonRefs.current[index] = el)} // ボタンの参照を設定
                    className="w-[24px]"
                  >
                    <span className="pt-5px material-symbols-rounded text-[24px]">
                      more_horiz
                    </span>
                  </div>
                </div>
                {isMenuOpen === index && (
                  <div
                    ref={menuRef}
                    style={{
                      position: "absolute",
                      top: `${menuPosition.top}px`,
                      left: `${menuPosition.left - 90}px`,
                    }}
                    className="z-10 w-[140px] h-[64px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                  >
                    <ul className="m-0 p-0">
                      <li
                        onClick={() => openCouponSettingModal(coupon.id)}
                        style={{
                          WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                          outline: "none", // タップ時のアウトラインを無効化
                        }}
                        className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <span className="material-symbols-rounded text-[20px]">
                          edit
                        </span>
                        <span className="w-[120px] h-[12px] text-#111827 font-hiragino leading-5">
                          配布設定を編集
                        </span>
                      </li>
                      <li
                        onClick={() => openStopModal(coupon.id)}
                        style={{
                          WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                          outline: "none", // タップ時のアウトラインを無効化
                        }}
                        className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <span className="material-symbols-rounded text-[20px] text-#B91C1C">
                          stop_circle
                        </span>
                        <span className="w-[120px] h-[12px] text-#B91C1C font-hiragino leading-5">
                          配布を中止
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {getDistributedCouponsByShopID?.activeCoupons.length == 0 &&
          getDistributedCouponsByShopID?.upcomingCoupons.length == 0 &&
          getDistributedCouponsByShopID?.otherCoupons.length == 0 && (
            <div className="flex flex-col gap-8px mt-4px h-136px py-48px ">
              <div className="flex justify-center">
                <span className="material-symbols-rounded text-[104px] text-#9CA3AF80">
                  confirmation_number
                </span>
              </div>
              <div className="text-16px text-tertiary text-center leading-5">
                配布中のクーポンはありません
              </div>
            </div>
          )}
      </div>
      {isCouponSettingModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 left-0 z-50 flex justify-center items-center box-border w-full h-screen px-20px backdrop-blur-sm bg-surfaceOverlay"
        >
          <div onClick={(e) => e.stopPropagation()} className="relative">
            <div
              className={`relative flex flex-col items-start gap-[20px] h-[83vh] overflow-y-auto p-[20px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow`}
            >
              <h3 className="text-[18px] m-0 font-semibold text-#111827 font-hiragino">
                クーポン配布設定編集
              </h3>
              <div className="flex flex-col gap-8px">
                <div className="">
                  <div></div>
                  <label
                    className="flex flex-col gap-[8px]"
                    htmlFor="originPrice"
                  >
                    <div className="flex gap-[8px]">
                      <p className="m-0 text-[16px] text-#111827 font-hiragino">
                        配布開始日時設定
                      </p>
                      <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </p>
                    </div>
                    <p className="m-0 text-14px font-hiragino text-secondary">
                      「設定しない」を選択した場合、即時公開されます
                    </p>
                  </label>
                  <form className="flex mt-[8px]">
                    <div className="flex items-center">
                      <input
                        id="inline-1-radio"
                        type="radio"
                        value="withoutDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        onChange={handleStartDateTimeRadioChange}
                        defaultChecked
                      />
                      <label
                        htmlFor="inline-1-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定しない
                      </label>
                    </div>
                    <div className="flex items-center ml-[8px]">
                      <input
                        id="inline-2-radio"
                        type="radio"
                        value="withDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        onChange={handleStartDateTimeRadioChange}
                      />
                      <label
                        htmlFor="inline-2-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定する
                      </label>
                    </div>
                  </form>
                  {isStartDateTimeFormOpen && (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="ja"
                      localeText={{
                        ...jaJP.components.MuiLocalizationProvider.defaultProps
                          .localeText,
                        cancelButtonLabel: "キャンセル",
                        okButtonLabel: "OK",
                      }}
                    >
                      <DemoContainer components={["DesktopDateTimePicker"]}>
                        <MobileDateTimePicker
                          label="配布開始日時を指定してください"
                          ampm={false}
                          value={startDateTime}
                          onChange={handleStartDateTimeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                </div>
                <div className="">
                  <label
                    className="flex flex-col gap-[8px]"
                    htmlFor="originPrice"
                  >
                    <div className="flex gap-[8px]">
                      <p className="m-0 text-[16px] text-#111827 font-hiragino">
                        配布終了日時設定
                      </p>
                      <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                        必須
                      </p>
                    </div>
                    <p className="m-0 text-14px font-hiragino text-secondary">
                      「設定しない」を選択した場合、中止をするまで公開され続けます
                    </p>
                  </label>
                  <form className="flex mt-[8px]">
                    <div className="flex items-center">
                      <input
                        id="inline-3-radio"
                        type="radio"
                        value="withoutDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                        onChange={handleEndDateTimeRadioChange}
                        defaultChecked
                      />
                      <label
                        htmlFor="inline-3-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定しない
                      </label>
                    </div>
                    <div className="flex items-center ml-[8px]">
                      <input
                        id="inline-4-radio"
                        type="radio"
                        value="withDate"
                        name="inline-radio-group"
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                        onChange={handleEndDateTimeRadioChange}
                      />
                      <label
                        htmlFor="inline-4-radio"
                        className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                      >
                        設定する
                      </label>
                    </div>
                  </form>
                  {isEndDateTimeFormOpen && (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="ja"
                      localeText={{
                        ...jaJP.components.MuiLocalizationProvider.defaultProps
                          .localeText,
                        cancelButtonLabel: "キャンセル",
                        okButtonLabel: "OK",
                      }}
                    >
                      <DemoContainer components={["DesktopDateTimePicker"]}>
                        <MobileDateTimePicker
                          label="配布終了日時を指定してください"
                          ampm={false}
                          value={endDateTime}
                          onChange={handleEndDateTimeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  )}
                  {endDateTimeError && (
                    <p className="text-red-500 text-sm mt-1">
                      {endDateTimeError}
                    </p>
                  )}
                </div>
                <div className="flex flex-col gap-8px">
                  <label
                    className="flex flex-col gap-[8px]"
                    htmlFor="originPrice"
                  >
                    <div className="flex gap-[8px]">
                      <p className="m-0 text-[16px] text-#111827 font-hiragino">
                        備考
                      </p>
                      <p className="m-0 text-[14px] text-tertiary font-hiragino leading-5">
                        任意
                      </p>
                    </div>
                    <p className="m-0 text-14px font-hiragino text-secondary">
                      賞味期限や消費期限など、このクーポン配布で伝えたい内容があればこちらに記載してください
                    </p>
                  </label>
                  <textarea
                    className="box-border h-[130px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[12px] px-[14px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                    id="couponName"
                    value={remarks}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setRemarks(e.target.value)
                    }
                    placeholder="例）賞味期限は1ヶ月ほどになります"
                  />
                </div>
              </div>
              <button
                type="button"
                style={{
                  WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                  outline: "none", // タップ時のアウトラインを無効化
                }}
                className={`block w-full h-[48px] py-[12px] px-[32px] text-[16px] font-medium font-hiragino border-none rounded-[12px]  ${isOkSettingButtonDisabled() ? "bg-[#E4E4E7] text-disableText" : "bg-[#001EFB] text-white hover:bg-#334BFC hover:cursor-pointer focus:ring-blue-300"}`}
                disabled={isOkSettingButtonDisabled()}
                onClick={handleUpdateSubmit}
              >
                OK
              </button>
              <button
                type="button"
                style={{
                  WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                  outline: "none", // タップ時のアウトラインを無効化
                }}
                className="block w-full h-[48px] px-[28px] py-[12px] text-[16px] text-#111827 rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 hover:cursor-pointer focus:z-10"
                onClick={closeCouponSettingModal}
              >
                キャンセル
              </button>
            </div>
          </div>
        </div>
      )}
      {isStopModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={() => {
            setSelectedCouponId("");
            setIsStopModalOpen(false);
          }}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-full px-16px"
          >
            <div className="relative flex flex-col items-start gap-[20px] box-border w-full p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <div className="flex flex-col gap-[20px] mx-auto">
                <div className="text-18px text-#111827 text-medium font-hiragino">
                  クーポンの配布を中止しますか
                </div>
                <div className="flex flex-col justify-center gap-12px">
                  <button
                    type="button"
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    className="block mx-auto w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC focus:ring-blue-300 cursor-pointer" // 通常時のスタイル
                    onClick={handleStopSubmit}
                  >
                    はい
                  </button>
                  <button
                    type="button"
                    style={{
                      WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                      outline: "none", // タップ時のアウトラインを無効化
                    }}
                    className="block mx-auto w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 bg-#F8FAFC rounded-[12px] font-semibold font-hiragino border-none focus:outline-none border-[1px] border-lightGray border-solid hover:bg-gray-100 cursor-pointer focus:z-10"
                    onClick={() => {
                      setSelectedCouponId("");
                      setIsStopModalOpen(false);
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showToast && (
        <Toast message={toastMessage} onClose={() => setShowToast(false)} />
      )}
    </>
  );
};

const DesktopLoginPage: React.FC<SharedProps> = ({
  isShowFinishedCoupons,
  toggleFinishedCoupons,
  currentPage,
  nextPage,
  prevPage,
  limit,
  getDistributedCouponsByShopID,
  distributedCouponID,
  toggleMenu,
  isMenuOpen,
  menuPosition,
  buttonRefs,
  menuRef,
  openCouponSettingModal,
  openStopModal,
  handleOverlayClick,
  handleStartDateTimeRadioChange,
  handleEndDateTimeRadioChange,
  handleStartDateTimeChange,
  handleEndDateTimeChange,
  isOkSettingButtonDisabled,
  handleUpdateSubmit,
  handleStopSubmit,
  isCouponSettingModalOpen,
  toastMessage,
  isStopModalOpen,
  showToast,
  isStartDateTimeFormOpen,
  startDateTime,
  isEndDateTimeFormOpen,
  endDateTime,
  setShowToast,
  endDateTimeError,
  remarks,
  setRemarks,
  closeCouponSettingModal,
  setIsStopModalOpen,
  setSelectedCouponId,
}) => {
  return (
    <>
      <div className="p-[24px]">
        <div className="flex justify-between">
          <h2 className="m-0 text-[20px] font-semibold leading-10 font-hiragino">
            クーポン配布状況
          </h2>
          <div className="flex gap-24px">
            <div className="text-14px font-hiragino leading-10">
              配布済クーポンを表示する
            </div>
            <label className="inline-flex items-center cursor-pointer">
              <input
                checked={isShowFinishedCoupons}
                onChange={toggleFinishedCoupons}
                type="checkbox"
                value=""
                className="sr-only peer"
              />
              <div className="relative w-28px h-28px pl-2px pr-22px bg-gray-200 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
        <div className="mt-[24px] bg-white rounded-t-[16px] border-[1px] border-lightGray border-solid">
          <div className="flex justify-end h-[54px] px-[24px] border-b-[1px] border-b-lightGray border-b-solid">
            <div className="flex gap-[16px] w-[130px]">
              <p className="w-[62px] my-0 text-[#111827] text-[12px] leading-14">
                {currentPage}/
                {Math.ceil((getDistributedCouponsByShopID?.total || 1) / limit)}
                ページ
              </p>
              <div className="flex gap-[4px] pt-[15px]">
                <div onClick={prevPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_back
                  </span>
                </div>
                <div onClick={nextPage}>
                  <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                    arrow_forward
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-[48px] px-[24px] gap-[12px]">
            <p className="m-0 w-[80px] text-[12px] font-hiragino text-secondary leading-12">
              ステータス
            </p>
            <p className="m-0 w-[72px] text-[12px] font-hiragino text-secondary leading-12">
              商品画像
            </p>
            <p className="m-0 w-[120px] text-[12px] font-hiragino text-secondary leading-12">
              商品名
            </p>
            <p className="m-0 w-[100px] text-[12px] font-hiragino text-secondary leading-12">
              割引
            </p>
            <p className="m-0 w-[120px] text-[12px] font-hiragino text-secondary leading-12">
              価格
            </p>
            <p className="m-0 w-[160px] text-[12px] font-hiragino text-secondary leading-12">
              配布期間
            </p>
            <p className="m-0 w-[60px] text-[12px] font-hiragino text-secondary leading-12">
              利用枚数
            </p>
          </div>
        </div>
        <div className="bg-white mt-4px rounded-b-[16px] border-x-[1px] border-x-lightGray border-x-solid ">
          {getDistributedCouponsByShopID?.activeCoupons.map((coupon, index) => {
            return (
              <div
                key={index}
                className={`flex h-[64px] px-[24px] gap-[12px] ${distributedCouponID == coupon.id && "bg-primaryLight"} border-b-[1px] border-b-lightGray border-b-solid ${index === getDistributedCouponsByShopID?.activeCoupons.length + getDistributedCouponsByShopID?.upcomingCoupons.length + getDistributedCouponsByShopID?.otherCoupons.length - 1 ? "rounded-b-[16px]" : ""}`}
              >
                <div className="w-[80px] whitespace-nowrap overflow-hidden text-ellipsis">
                  <p className="mt-[19px] w-[42px] h-[20px] px-[6px] py-[2px] bg-#EFF6FF border-[1px] border-#93C5FD border-solid rounded-[4px] text-[14px] text-#1D4ED8 font-hiragino font-medium leading-5">
                    配布中
                  </p>
                </div>
                <div className="">
                  {coupon.imageBase64 ? (
                    <img
                      src={coupon.imageBase64}
                      alt={coupon.couponName}
                      className="w-72px h-42px mt-10px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                    />
                  ) : (
                    <p>image</p> // 画像が存在しない場合のプレースホルダー
                  )}
                </div>
                <p className="w-[120px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {coupon.couponName}
                </p>
                <p className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {coupon.discountMethod == "percentage_discount"
                    ? coupon.discountValue + "%OFF"
                    : coupon.discountValue + "円引き"}
                </p>
                <p className="mt-[20px] w-[120px] h-[32px] text-[12px] font-hiragino text-#111827">
                  {coupon.isSetOriginalPrice
                    ? coupon.discountMethod === "percentage_discount"
                      ? // パーセント割引の場合
                        `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                      : // 固定金額割引の場合
                        `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                    : "-"}
                  <br />
                  {coupon.isSetOriginalPrice &&
                    `(定価${Number(coupon.originalPrice)}円)`}
                </p>
                <p className="mt-[20px] w-[160px] text-[12px] font-hiragino text-#111827">
                  {formatTimestamp(coupon.distributionStartAt as Timestamp) ===
                    "" &&
                  formatTimestamp(coupon.distributionEndAt as Timestamp) ===
                    "" ? (
                    "-"
                  ) : (
                    <>
                      {formatTimestamp(coupon.distributionStartAt as Timestamp)}{" "}
                      〜
                      <br />
                      {formatTimestamp(coupon.distributionEndAt as Timestamp)}
                    </>
                  )}
                </p>
                <p className="w-[60px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {Number(coupon.usageCount)}
                </p>
                <div
                  onClick={() => toggleMenu(index)}
                  ref={(el) => (buttonRefs.current[index] = el)}
                  className="w-[24px] p-[4px] ml-auto"
                >
                  <span className="material-symbols-rounded text-[24px] mt-[15px] cursor-pointer rounded-[9999px]">
                    more_vert
                  </span>
                </div>
                {isMenuOpen === index && (
                  <div
                    ref={menuRef}
                    style={{
                      position: "absolute",
                      top: `${menuPosition.top}px`,
                      left: `${menuPosition.left - 90}px`,
                    }}
                    className="z-10 w-[140px] h-[64px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                  >
                    <ul className="m-0 p-0">
                      <li
                        onClick={() => openCouponSettingModal(coupon.id)}
                        className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <span className="material-symbols-rounded text-[20px]">
                          edit
                        </span>
                        <span className="w-[120px] h-[12px] text-#111827 font-hiragino leading-5">
                          配布設定を編集
                        </span>
                      </li>
                      <li
                        onClick={() => openStopModal(coupon.id)}
                        className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                      >
                        <span className="material-symbols-rounded text-[20px] text-#B91C1C">
                          stop_circle
                        </span>
                        <span className="w-[120px] h-[12px] text-#B91C1C font-hiragino leading-5">
                          配布を中止
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            );
          })}
          {getDistributedCouponsByShopID?.upcomingCoupons.map(
            (coupon, index) => {
              const upcomingIndex =
                index + getDistributedCouponsByShopID.activeCoupons.length;
              return (
                <div
                  key={upcomingIndex}
                  className={`flex h-[64px] px-[24px] gap-[12px] ${distributedCouponID == coupon.id && "bg-primaryLight"} border-b-[1px] border-b-lightGray border-b-solid ${index + getDistributedCouponsByShopID?.activeCoupons.length === getDistributedCouponsByShopID?.activeCoupons.length + getDistributedCouponsByShopID?.upcomingCoupons.length + getDistributedCouponsByShopID?.otherCoupons.length - 1 ? "rounded-b-[16px]" : ""}`}
                >
                  <div className="w-[80px] whitespace-nowrap overflow-hidden text-ellipsis">
                    <p className="mt-[19px] w-[42px] h-[20px] px-[6px] py-[2px] bg-#FFF7ED border-[1px] border-#FDBA74 border-solid rounded-[4px] text-[14px] text-#C2410C font-hiragino font-medium leading-5">
                      配布前
                    </p>
                  </div>
                  <div className="">
                    {coupon.imageBase64 ? (
                      <img
                        src={coupon.imageBase64}
                        alt={coupon.couponName}
                        className="w-72px h-42px mt-10px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                      />
                    ) : (
                      <p>image</p> // 画像が存在しない場合のプレースホルダー
                    )}
                  </div>
                  <p className="w-[120px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                    {coupon.couponName}
                  </p>
                  <p className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                    {coupon.discountMethod == "percentage_discount"
                      ? coupon.discountValue + "%OFF"
                      : coupon.discountValue + "円引き"}
                  </p>
                  <p className="mt-[20px] w-[120px] h-[32px] text-[12px] font-hiragino text-#111827">
                    {coupon.isSetOriginalPrice
                      ? coupon.discountMethod === "percentage_discount"
                        ? // パーセント割引の場合
                          `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                        : // 固定金額割引の場合
                          `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                      : "-"}
                    <br />
                    {coupon.isSetOriginalPrice &&
                      `(定価${Number(coupon.originalPrice)}円)`}
                  </p>
                  <p className="mt-[20px] w-[160px] text-[12px] font-hiragino text-#111827">
                    {formatTimestamp(
                      coupon.distributionStartAt as Timestamp
                    ) === "" &&
                    formatTimestamp(coupon.distributionEndAt as Timestamp) ===
                      "" ? (
                      "-"
                    ) : (
                      <>
                        {formatTimestamp(
                          coupon.distributionStartAt as Timestamp
                        )}{" "}
                        〜
                        <br />
                        {formatTimestamp(coupon.distributionEndAt as Timestamp)}
                      </>
                    )}
                  </p>
                  <p className="w-[60px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                    {Number(coupon.usageCount)}
                  </p>
                  <div
                    onClick={() => toggleMenu(upcomingIndex)} // Upcoming Coupons にオフセット付きのインデックスを使用
                    ref={(el) => (buttonRefs.current[upcomingIndex] = el)} // ボタンの参照を設定
                    className="w-[24px] p-[4px] ml-auto"
                  >
                    <span className="material-symbols-rounded text-[24px] mt-[15px] cursor-pointer rounded-[9999px]">
                      more_vert
                    </span>
                  </div>
                  {isMenuOpen === upcomingIndex && (
                    <div
                      ref={menuRef}
                      style={{
                        position: "absolute",
                        top: `${menuPosition.top}px`,
                        left: `${menuPosition.left - 90}px`,
                      }}
                      className="z-10 w-[140px] h-[64px] p-[4px] bg-white rounded-[8px] border-[1px] border-solid border-lightGray shadow-DropShadowLow"
                    >
                      <ul className="m-0 p-0">
                        <li
                          onClick={() => openCouponSettingModal(coupon.id)}
                          className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                        >
                          <span className="material-symbols-rounded text-[20px]">
                            edit
                          </span>
                          <span className="w-[120px] h-[12px] text-#111827 font-hiragino leading-5">
                            配布設定を編集
                          </span>
                        </li>
                        <li
                          onClick={() => openStopModal(coupon.id)}
                          className="flex gap-[2px] w-[120px] h-[16px] px-[10px] py-[8px] rounded-[4px] text-[14px] list-none hover:bg-gray-100 cursor-pointer"
                        >
                          <span className="material-symbols-rounded text-[20px] text-#B91C1C">
                            stop_circle
                          </span>
                          <span className="w-[120px] h-[12px] text-#B91C1C font-hiragino leading-5">
                            配布を中止
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              );
            }
          )}
          {getDistributedCouponsByShopID?.otherCoupons.map((coupon, index) => {
            return (
              <div
                key={index}
                className={`flex h-[64px] px-[24px] gap-[12px] ${distributedCouponID == coupon.id && "bg-primaryLight"} border-b-[1px] border-b-lightGray border-b-solid ${index === getDistributedCouponsByShopID?.otherCoupons.length - 1 ? "rounded-b-[16px]" : ""}`}
              >
                <div className="w-[80px] whitespace-nowrap overflow-hidden text-ellipsis">
                  <p className="mt-[19px] w-[42px] h-[20px] px-[6px] py-[2px] bg-#F9FAFB border-[1px] border-#D1D5DB border-solid rounded-[4px] text-[14px] text-#374151 font-hiragino font-medium leading-5">
                    配布済
                  </p>
                </div>
                <div className="">
                  {coupon.imageBase64 ? (
                    <img
                      src={coupon.imageBase64}
                      alt={coupon.couponName}
                      className="w-72px h-42px mt-10px rounded-4px border-solid border-1px border-lightGray" // 画像を全体に表示し、オブジェクトカバーでサイズに応じてトリミング
                    />
                  ) : (
                    <p>image</p> // 画像が存在しない場合のプレースホルダー
                  )}
                </div>
                <p className="w-[120px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {coupon.couponName}
                </p>
                <p className="w-[100px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {coupon.discountMethod == "percentage_discount"
                    ? coupon.discountValue + "%OFF"
                    : coupon.discountValue + "円引き"}
                </p>
                <p className="mt-[20px] w-[120px] h-[32px] text-[12px] font-hiragino text-#111827">
                  {coupon.isSetOriginalPrice
                    ? coupon.discountMethod === "percentage_discount"
                      ? // パーセント割引の場合
                        `${Math.round(Number(coupon.originalPrice) * (1 - Number(coupon.discountValue) / 100))}円`
                      : // 固定金額割引の場合
                        `${Math.max(Number(coupon.originalPrice) - Number(coupon.discountValue), 0)}円`
                    : "-"}
                  <br />
                  {coupon.isSetOriginalPrice &&
                    `(定価${Number(coupon.originalPrice)}円)`}
                </p>
                <p className="mt-[20px] w-[160px] text-[12px] font-hiragino text-#111827">
                  {formatTimestamp(coupon.distributionStartAt as Timestamp) ===
                    "" &&
                  formatTimestamp(coupon.distributionEndAt as Timestamp) ===
                    "" ? (
                    "-"
                  ) : (
                    <>
                      {formatTimestamp(coupon.distributionStartAt as Timestamp)}{" "}
                      〜
                      <br />
                      {formatTimestamp(coupon.distributionEndAt as Timestamp)}
                    </>
                  )}
                </p>
                <p className="w-[60px] whitespace-nowrap overflow-hidden text-ellipsis text-[12px] leading-10 font-hiragino text-#111827">
                  {Number(coupon.usageCount)}
                </p>
              </div>
            );
          })}
        </div>
        {getDistributedCouponsByShopID?.activeCoupons.length == 0 &&
          getDistributedCouponsByShopID?.upcomingCoupons.length == 0 &&
          getDistributedCouponsByShopID?.otherCoupons.length == 0 && (
            <div className="flex flex-col gap-8px mt-4px h-136px py-48px ">
              <div className="flex justify-center">
                <span className="material-symbols-rounded text-[104px] text-#9CA3AF80">
                  confirmation_number
                </span>
              </div>
              <div className="text-16px text-tertiary text-center leading-5">
                配布中のクーポンはありません
              </div>
            </div>
          )}
      </div>
      {isCouponSettingModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={handleOverlayClick}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[492px] h-[356px] mt-[-240px]"
          >
            <div
              className={`relative flex flex-col items-start gap-[20px]  w-[492px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow`}
            >
              <h3 className="text-[18px] m-0 font-semibold text-#111827 font-hiragino">
                クーポン配布設定編集
              </h3>
              <div className="w-[492px]">
                <label
                  className="flex flex-col gap-[8px]"
                  htmlFor="originPrice"
                >
                  <div className="flex gap-[8px]">
                    <p className="m-0 text-[16px] text-#111827 font-hiragino">
                      配布開始日時設定
                    </p>
                    <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                      必須
                    </p>
                  </div>
                  <p className="m-0 text-14px font-hiragino text-secondary">
                    「設定しない」を選択した場合、即時公開されます
                  </p>
                </label>
                <form className="flex mt-[8px]">
                  <div className="flex items-center">
                    <input
                      id="inline-1-radio"
                      type="radio"
                      value="withoutDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={handleStartDateTimeRadioChange}
                      defaultChecked
                    />
                    <label
                      htmlFor="inline-1-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex items-center ml-[8px]">
                    <input
                      id="inline-2-radio"
                      type="radio"
                      value="withDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={handleStartDateTimeRadioChange}
                    />
                    <label
                      htmlFor="inline-2-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定する
                    </label>
                  </div>
                </form>
                {isStartDateTimeFormOpen && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja"
                  >
                    <DemoContainer components={["DesktopDateTimePicker"]}>
                      <DesktopDateTimePicker
                        label="配布開始日時を指定してください"
                        ampm={false}
                        value={startDateTime}
                        onChange={handleStartDateTimeChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
              </div>
              <div className="w-[492px]">
                <label
                  className="flex flex-col gap-[8px]"
                  htmlFor="originPrice"
                >
                  <div className="flex gap-[8px]">
                    <p className="m-0 text-[16px] text-#111827 font-hiragino">
                      配布終了日時設定
                    </p>
                    <p className="m-0 text-[14px] text-#B91C1C font-hiragino leading-5">
                      必須
                    </p>
                  </div>
                  <p className="m-0 text-14px font-hiragino text-secondary">
                    「設定しない」を選択した場合、中止をするまで公開され続けます
                  </p>
                </label>
                <form className="flex mt-[8px]">
                  <div className="flex items-center">
                    <input
                      id="inline-1-radio"
                      type="radio"
                      value="withoutDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 "
                      onChange={handleEndDateTimeRadioChange}
                      defaultChecked
                    />
                    <label
                      htmlFor="inline-1-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定しない
                    </label>
                  </div>
                  <div className="flex items-center ml-[8px]">
                    <input
                      id="inline-4-radio"
                      type="radio"
                      value="withDate"
                      name="inline-radio-group"
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                      onChange={handleEndDateTimeRadioChange}
                    />
                    <label
                      htmlFor="inline-4-radio"
                      className="ml-[4px] text-[16px] text-#111827 font-hiragino"
                    >
                      設定する
                    </label>
                  </div>
                </form>
                {isEndDateTimeFormOpen && (
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale="ja"
                  >
                    <DemoContainer components={["DesktopDateTimePicker"]}>
                      <DesktopDateTimePicker
                        label="配布終了日時を指定してください"
                        ampm={false}
                        value={endDateTime}
                        onChange={handleEndDateTimeChange}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                )}
                {endDateTimeError && (
                  <p className="text-red-500 text-sm mt-1">
                    {endDateTimeError}
                  </p>
                )}
              </div>
              <div className="flex flex-col gap-8px">
                <label
                  className="flex flex-col gap-[8px]"
                  htmlFor="originPrice"
                >
                  <div className="flex gap-[8px]">
                    <p className="m-0 text-[16px] text-#111827 font-hiragino">
                      備考
                    </p>
                    <p className="m-0 text-[14px] text-tertiary font-hiragino leading-5">
                      任意
                    </p>
                  </div>
                  <p className="m-0 text-14px font-hiragino text-secondary">
                    賞味期限や消費期限など、このクーポン配布で伝えたい内容があればこちらに記載してください
                  </p>
                </label>
                <textarea
                  className="w-494px box-border h-[130px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[12px] px-[14px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  value={remarks}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setRemarks(e.target.value)
                  }
                  placeholder="例）賞味期限は1ヶ月ほどになります"
                />
              </div>
              <div className="flex justify-end w-[492px] h-[48px] gap-[12px]">
                <button
                  type="button"
                  className="block w-[136px] h-[48px] px-[28px] py-[12px] text-[16px] text-#111827 rounded-[12px] font-medium font-hiragino border-none focus:outline-none bg-white  hover:bg-gray-100 cursor-pointer focus:z-10"
                  onClick={closeCouponSettingModal}
                >
                  キャンセル
                </button>
                <button
                  type="button"
                  className={`block  w-[90px] h-[48px] py-[12px] px-[32px] text-[16px] font-medium font-hiragino border-none rounded-[12px]  ${isOkSettingButtonDisabled() ? "bg-[#E4E4E7] text-disableText" : "bg-[#001EFB] text-white hover:bg-#334BFC focus:ring-blue-300 cursor-pointer"}`}
                  // onClick={handleDistributionSubmit}
                  disabled={isOkSettingButtonDisabled()}
                  onClick={handleUpdateSubmit}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isStopModalOpen && (
        <div
          id="modal-overlay"
          tabIndex={-1}
          onClick={() => {
            setSelectedCouponId("");
            setIsStopModalOpen(false);
          }}
          aria-hidden="true"
          className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="relative w-[432px] h-[92px]"
          >
            <div className="relative flex flex-col items-start gap-[20px] w-[432px] h-[92px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
              <div className="flex flex-col gap-[20px]">
                <div className="text-18px text-#111827 text-medium font-hiragino">
                  クーポンの配布を中止しますか
                </div>
                <div className="flex justify-center gap-12px">
                  <button
                    type="button"
                    className="block w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 bg-#F8FAFC rounded-[12px] font-semibold font-hiragino border-none focus:outline-none border-[1px] border-lightGray border-solid hover:bg-gray-100 cursor-pointer focus:z-10"
                    onClick={() => {
                      setSelectedCouponId("");
                      setIsStopModalOpen(false);
                    }}
                  >
                    キャンセル
                  </button>
                  <button
                    type="button"
                    className="block w-[210px] h-[48px] py-[12px] px-[24px] text-[16px] font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC focus:ring-blue-300 cursor-pointer" // 通常時のスタイル
                    onClick={handleStopSubmit}
                  >
                    はい
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showToast && (
        <Toast message={toastMessage} onClose={() => setShowToast(false)} />
      )}
    </>
  );
};

export default DistributedCouponListPage;
