import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import GoogleMapsPicker from "../organisms/google-maps-picker";
import { useMutationEditShop, useQueryGetShopByID } from "../../hooks/useQuery";

const ShopEditPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [imageData, setImageData] = useState<Uint8Array | undefined>(undefined);
  const [openingHours, setOpeningHours] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [url, setUrl] = useState("");
  const [latitude, setLatitude] = useState(35.681352085905964);
  const [longitude, setLongitude] = useState(139.76711235481022);
  const [errorMessage, setErrorMessage] = useState("");
  const editShop = useMutationEditShop();
  const { data: getShopByID } = useQueryGetShopByID();
  const [hasPushedButtonWithMap, setHasPushedButtonWithMap] = useState(true);
  const [initialLatitude, setInitialLatitude] = useState(35.681352085905964);
  const [initialLongitude, setInitialLongitude] = useState(139.76711235481022);
  const [descriptionError, setDescriptionError] = useState("");
  const [imageError, setImageError] = useState("");
  const [openingHoursError, setOpeningHoursError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [mapError, setMapError] = useState("");
  errorMessage;

  const handleAddressToCoordinates = async () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results?.[0].geometry.location) {
        const location = results[0].geometry.location;
        setLatitude(location.lat());
        setLongitude(location.lng());
        setInitialLatitude(location.lat());
        setInitialLongitude(location.lng());
        setHasPushedButtonWithMap(true); // 地図に操作があったことを記録
        setErrorMessage("");
      } else {
        setErrorMessage("住所の変換に失敗しました");
      }
    });
  };

  // 不要な部分を取り除く関数
  const cleanAddress = (address: string): string => {
    return address
      .replace(/^日本[,\s]*/, "") // 「日本、」や「日本 」を先頭から削除
      .replace(/〒\d{3}-\d{4}\s*/, "") // 「〒100-0005」などの郵便番号を削除
      .replace(/^、\s*/, "") // 先頭に残る「、」を削除
      .trim(); // 余分なスペースを削除
  };

  // 座標から住所を取得する関数
  const handleCoordinatesToAddress = async () => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = new window.google.maps.LatLng(latitude, longitude);

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results?.[0]) {
        const cleanedAddress = cleanAddress(results[0].formatted_address);
        setAddress(cleanedAddress);
        setInitialLatitude(latitude);
        setInitialLongitude(longitude);
        setHasPushedButtonWithMap(true); // 地図に操作があったことを記録
        setErrorMessage("");
      } else {
        setErrorMessage("住所の取得に失敗しました");
      }
    });
  };

  const removeImage = () => {
    setImagePreview(null);
    setImageData(undefined);
  };

  const handleSubmit = async () => {
    let hasError = false;

    // 初期化
    setDescriptionError("");
    setImageError("");
    setOpeningHoursError("");
    setAddressError("");
    setMapError("");

    // ショップ説明のバリデーション
    if (description.trim() === "") {
      setDescriptionError("ショップ説明は必須です。");
      hasError = true;
    }

    // ショップ画像のバリデーション
    if (imageData === undefined) {
      setImageError("ショップ画像は必須です。");
      hasError = true;
    }

    // 営業時間のバリデーション
    if (openingHours.trim() === "") {
      setOpeningHoursError("営業時間は必須です。");
      hasError = true;
    }

    // 住所のバリデーション
    if (address.trim() === "") {
      setAddressError("住所は必須です。");
      hasError = true;
    }

    // 住所を入力した後に「住所から地図にピンを刺す」「地図のピンから住所を取得する」ボタンを押しているか
    if (!hasPushedButtonWithMap) {
      setMapError(
        "「住所から地図にピンを刺す」か「地図のピンから住所を取得する」のどちらかをクリックしてください。"
      );
      hasError = true;
    }

    // マップのバリデーション:「住所から地図にピンを刺す」「地図のピンから住所を取得する」ボタンを押した後にピンを変更していないか
    if (!(latitude === initialLatitude && longitude === initialLongitude)) {
      setMapError(
        "「住所から地図にピンを刺す」や「地図のピンから住所を取得する」をクリックした後に地図上のピンを移動しないでください。"
      );
      hasError = true;
    }

    // エラーがある場合は処理を中断
    if (hasError) {
      return;
    }

    // エラーが無い場合はエラーメッセージをリセットして処理を進める
    try {
      await editShop.mutateAsync({
        shop: {
          description: description,
          imageData: imageData,
          openingHours: openingHours,
          address: address,
          latitude: latitude,
          longitude: longitude,
          phoneNumber: phoneNumber,
          homepageUrl: url,
        },
      });
      navigate("/shop_setting");
    } catch (error) {
      setErrorMessage("登録に失敗しました。もう一度お試しください。");
    }
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.result instanceof ArrayBuffer) {
          const arrayBuffer = reader.result;
          const uint8Array = new Uint8Array(arrayBuffer); // ArrayBufferをUint8Arrayに変換
          setImageData(uint8Array); // Uint8Arrayをstateにセット

          const base64String = btoa(
            new Uint8Array(arrayBuffer).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          setImagePreview(`data:${file.type};base64,${base64String}`); // プレビュー用にBase64変換
        }
      };
      reader.readAsArrayBuffer(file); // ArrayBufferとして読み込む
    }
  };

  const isFormValid = (): boolean => {
    return (
      description.trim() !== "" &&
      imageData !== undefined &&
      openingHours.trim() !== "" &&
      address.trim() !== ""
    );
  };

  useEffect(() => {
    if (
      getShopByID &&
      !description &&
      !openingHours &&
      !address &&
      !imagePreview &&
      !imageData &&
      !phoneNumber &&
      !url
    ) {
      setDescription(getShopByID.shop?.description || "");
      setOpeningHours(getShopByID.shop?.openingHours || "");
      setAddress(getShopByID.shop?.address || "");
      setLatitude(getShopByID.shop?.latitude || 35.68123620000001);
      setLongitude(getShopByID.shop?.longitude || 139.7671248);
      setInitialLatitude(getShopByID.shop?.latitude || 35.68123620000001);
      setInitialLongitude(getShopByID.shop?.longitude || 139.7671248);
      setPhoneNumber(getShopByID.shop?.phoneNumber || "");
      setUrl(getShopByID.shop?.homepageUrl || "");
      if (getShopByID.shop?.imageBase64) {
        const base64Image = getShopByID.shop?.imageBase64 || "";
        if (base64Image.startsWith("data:image/")) {
          setImagePreview(base64Image); // 既に"data:image/..."がある場合はそのまま使う
        } else {
          setImagePreview(`data:image/png;base64,${base64Image}`); // 無い場合だけ追加する
        }
        // Uint8Array に変換して imageData にセットする処理
        const base64Data = base64Image.split(",")[1]; // "data:image/png;base64,"の部分を取り除く
        const binaryString = window.atob(base64Data); // base64 をデコードしてバイナリ文字列に変換
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i); // バイナリ文字列を Uint8Array に変換
        }
        setImageData(bytes);
      }
    }
  }, [getShopByID]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sharedProps: SharedProps = {
    navigate,
    description,
    setDescription,
    imagePreview,
    openingHours,
    setOpeningHours,
    address,
    setAddress,
    phoneNumber,
    setPhoneNumber,
    url,
    setUrl,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    setHasPushedButtonWithMap,
    descriptionError,
    imageError,
    openingHoursError,
    addressError,
    mapError,
    handleAddressToCoordinates,
    handleCoordinatesToAddress,
    removeImage,
    handleSubmit,
    handleImageUpload,
    isFormValid,
    getShopByID,
  };

  return isMobile ? (
    <MobileLoginPage {...sharedProps} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
};

interface SharedProps {
  navigate: NavigateFunction;
  description: string;
  setDescription: (value: string) => void;
  imagePreview: string | null;
  openingHours: string;
  setOpeningHours: (value: string) => void;
  address: string;
  setAddress: (value: string) => void;
  phoneNumber: string;
  setPhoneNumber: (value: string) => void;
  url: string;
  setUrl: (value: string) => void;
  latitude: number;
  setLatitude: (value: number) => void;
  longitude: number;
  setLongitude: (value: number) => void;
  setHasPushedButtonWithMap: (value: boolean) => void;
  descriptionError: string;
  imageError: string;
  openingHoursError: string;
  addressError: string;
  mapError: string;
  handleAddressToCoordinates: () => Promise<void>;
  handleCoordinatesToAddress: () => Promise<void>;
  removeImage: () => void;
  handleSubmit: () => Promise<void>;
  handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFormValid: () => boolean;
  getShopByID:
    | {
        shop?: {
          name?: string;
          description?: string;
          openingHours?: string;
          address?: string;
          latitude?: number;
          longitude?: number;
          phoneNumber?: string;
          homepageUrl?: string;
          imageBase64?: string;
        };
      }
    | undefined;
}

const MobileLoginPage: React.FC<SharedProps> = ({
  navigate,
  description,
  setDescription,
  imagePreview,
  openingHours,
  setOpeningHours,
  address,
  setAddress,
  phoneNumber,
  setPhoneNumber,
  url,
  setUrl,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  setHasPushedButtonWithMap,
  descriptionError,
  imageError,
  openingHoursError,
  addressError,
  mapError,
  handleAddressToCoordinates,
  handleCoordinatesToAddress,
  removeImage,
  handleSubmit,
  handleImageUpload,
  isFormValid,
  getShopByID,
}) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  return (
    <>
      <div className="p-16px">
        <div className="flex flex-col gap-[16px] grow">
          <div className="flex">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                arrow_back
              </span>
            </div>
            <h2 className="m-0 text-[20px] font-semibold font-hiragino">
              編集
            </h2>
          </div>
          <div className="">
            <form className="flex flex-col gap-[16px]  p-[20px] rounded-[16px] bg-white">
              <div className="flex flex-col gap-[8px]">
                <div className="flex flex-col gap-4px">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ショップ名
                  </div>
                  <div className="text-14px text-secondary font-hiragino">
                    ※ショップ名を編集する場合は、管理組織にご連絡ください
                  </div>
                </div>
                <div className="text-16px text-#111827 font-hiragino break-all">
                  {getShopByID?.shop?.name}
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ショップ説明
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[150px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  placeholder="ショップの説明を入力してください"
                  value={description}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setDescription(event.target.value);
                  }}
                />
                {descriptionError && (
                  <p className="text-red-500">{descriptionError}</p>
                )}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ショップ画像
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                {imagePreview ? (
                  <div className="relative w-32 h-32 mb-4">
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <button
                      onClick={removeImage}
                      className="absolute top-0 right-0 text-white w-5 h-5 flex items-center justify-center"
                    >
                      <span className="material-symbols-rounded text-[20px] text-black hover:cursor-pointer">
                        close
                      </span>
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="dropzone-file"
                    className="w-[106px] h-[24px] pl-[20px] pr-[24px] py-[8px] border-solid border-[1px] border-lightGray rounded-[12px] cursor-pointer bg-#F8FAFC  hover:bg-gray-100"
                  >
                    <div className="flex gap-[2px]">
                      <span className="material-symbols-rounded text-[24px]">
                        upload
                      </span>
                      <div className="text-[16px] text-#111827 font-hiragino font-medium leading-6">
                        画像を追加
                      </div>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/png, image/jpeg"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </label>
                )}
                {imageError && <p className="text-red-500">{imageError}</p>}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    営業時間
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[150px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  placeholder="営業時間を入力してください"
                  value={openingHours}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setOpeningHours(event.target.value);
                  }}
                />
                {openingHoursError && (
                  <p className="text-red-500">{openingHoursError}</p>
                )}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    住所
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="住所を入力してください"
                  value={address}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAddress(event.target.value);
                    setHasPushedButtonWithMap(false);
                  }}
                />
                {addressError && <p className="text-red-500">{addressError}</p>}
                <div className="flex flex-col gap-12px">
                  <GoogleMapsPicker
                    latitute={latitude}
                    longitude={longitude}
                    onChangeLocation={({ latitute, longitude }) => {
                      setLatitude(latitute);
                      setLongitude(longitude);
                    }}
                  />
                  <div className="flex flex-col gap-[16px] mx-auto">
                    <button
                      type="button"
                      onClick={handleAddressToCoordinates}
                      className="block flex gap-2px mx-auto w-[212px] h-[38px] py-[8px] px-[10px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
                    >
                      <span className="material-symbols-rounded text-[20px]">
                        add_location
                      </span>
                      <div className="text-14px font-semibold text-#111827 font-hiragino leading-5">
                        住所から地図にピンを刺す
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={handleCoordinatesToAddress}
                      className="block flex gap-2px mx-auto w-[240px] h-[38px] py-[8px] px-[10px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
                    >
                      <span className="material-symbols-rounded text-[20px]">
                        explore
                      </span>
                      <div className="text-14px font-semibold text-#111827 font-hiragino leading-5">
                        地図のピンから住所を取得する
                      </div>
                    </button>
                  </div>
                </div>
                {mapError && <p className="text-red-500">{mapError}</p>}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    お問い合わせ（電話番号）
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="例）01234567890（ハイフンなし）"
                  value={phoneNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPhoneNumber(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ホームページURL
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="例) https://shop.example.com"
                  value={url}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUrl(event.target.value);
                  }}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="mt-16px bg-#D1D5DB80">
          <div
            className={`flex justify-around m-0 py-24px h-24px ${isPreviewVisible && "border-b-solid border-b-1px border-b-disableText"}`}
          >
            <div className="w-24px"></div>
            <h2 className="m-0 text-center text-18px font-semibold text-#111827 font-hiragino leading-7">
              プレビュー
            </h2>
            <div
              style={{
                WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                outline: "none", // タップ時のアウトラインを無効化
              }}
              onClick={() => setIsPreviewVisible(!isPreviewVisible)}
              className=""
            >
              <span className="material-symbols-rounded text-[24px]">
                {isPreviewVisible ? "expand_less" : "expand_more"}
              </span>
            </div>
          </div>
          {isPreviewVisible && (
            <div className="flex flex-col gap-24px p-24px">
              <div className="mx-auto h-24px py-8px px-16px rounded-9999px bg-lightGray text-18px font-semibold text-secondary font-hiragino leading-6">
                ショップ詳細画面
              </div>
              <div className="flex flex-col gap-12px p-16px bg-#FFF rounded-12px">
                <div className="text-16px text-#111827 font-hiragino break-all">
                  {getShopByID?.shop?.name}
                </div>
                {description && (
                  <div className="text-14px text-#303846 font-hiragino">
                    <div
                      className="break-all"
                      dangerouslySetInnerHTML={{
                        __html: description.replace(/\n/g, "<br />"),
                      }}
                    />
                  </div>
                )}
                {imagePreview ? (
                  <div className="w-full aspect-[4/3] mx-auto rounded-8px overflow-hidden">
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"
                      className="w-full h-full object-cover"
                    />
                  </div>
                ) : (
                  <div className="mx-auto w-full h-139px rounded-15px bg-lightGray text-center">
                    <span className="material-symbols-rounded pt-37px text-[60px] text-#4B556380 rounded-9999">
                      storefront
                    </span>
                  </div>
                )}
                {openingHours && (
                  <div className="flex flex-col gap-8px">
                    <div className="text-14px font-semibold text-#303846 font-hiragino">
                      営業時間
                    </div>
                    <div className="text-14px text-#303846 font-hiragino">
                      <div
                        className="break-all"
                        dangerouslySetInnerHTML={{
                          __html: openingHours.replace(/\n/g, "<br />"),
                        }}
                      />
                    </div>
                  </div>
                )}
                {address && (
                  <div className="flex flex-col gap-8px">
                    <div className="text-14px font-semibold text-#303846 font-hiragino">
                      住所
                    </div>
                    <div className="text-14px text-#001EFB font-hiragino break-all">
                      {address}
                    </div>
                  </div>
                )}
                <GoogleMapsPicker
                  latitute={latitude}
                  longitude={longitude}
                  onChangeLocation={() => {}}
                />
                {phoneNumber && (
                  <div className="flex flex-col gap-8px">
                    <div className="text-14px font-semibold text-#303846 font-hiragino">
                      お問合せ
                    </div>
                    <div className="flex gap-2px w-116px h-20px py-6px pl-8px pr-12px rounded-12px border-solid border-1px border-disableText bg-transparent">
                      <span className="material-symbols-rounded text-[12.75px] text-#374151CC leading-5.5">
                        call
                      </span>
                      <div className="leading-5 text-12px text-secondary font-semibold font-hiragino">
                        {phoneNumber}
                      </div>
                    </div>
                  </div>
                )}
                {url && (
                  <div className="flex flex-col gap-8px">
                    <div className="text-14px font-semibold text-#303846 font-hiragino">
                      ホームページ
                    </div>
                    <div className="text-14px text-#001EFB font-hiragino break-all">
                      {url}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="py-16px">
          <button
            type="button"
            style={{
              WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
              outline: "none", // タップ時のアウトラインを無効化
            }}
            onClick={handleSubmit}
            disabled={!isFormValid()}
            className={`block mx-auto w-[200px] h-[48px] py-[12px] pl-[28px] pr-[32px] rounded-[12px] ${
              isFormValid()
                ? "bg-#001CEC text-white hover:bg-#334BFC cursor-pointer"
                : "bg-#E4E4E7 text-disableText cursor-not-allowed"
            } border-none focus:outline-none`}
          >
            保存する
          </button>
        </div>
      </div>
    </>
  );
};

const DesktopLoginPage: React.FC<SharedProps> = ({
  navigate,
  description,
  setDescription,
  imagePreview,
  openingHours,
  setOpeningHours,
  address,
  setAddress,
  phoneNumber,
  setPhoneNumber,
  url,
  setUrl,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  setHasPushedButtonWithMap,
  descriptionError,
  imageError,
  openingHoursError,
  addressError,
  mapError,
  handleAddressToCoordinates,
  handleCoordinatesToAddress,
  removeImage,
  handleSubmit,
  handleImageUpload,
  isFormValid,
  getShopByID,
}) => {
  return (
    <>
      <div className="flex">
        <div className="flex flex-col gap-[24px] grow p-[24px]">
          <div className="flex">
            <div
              onClick={() => {
                navigate(-1);
              }}
            >
              <span className="material-symbols-rounded text-[24px] rounded-999 hover:cursor-pointer">
                arrow_back
              </span>
            </div>
            <h2 className="m-0 text-[20px] font-semibold font-hiragino">
              編集
            </h2>
          </div>
          <div className="">
            <form className="flex flex-col gap-[16px]  p-[24px] rounded-[16px] bg-white">
              <div className="flex flex-col gap-[8px]">
                <div className="flex flex-col gap-4px">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ショップ名
                  </div>
                  <div className="text-14px text-secondary font-hiragino">
                    ※ショップ名を編集する場合は、管理組織にご連絡ください
                  </div>
                </div>
                <div className="text-16px text-#111827 font-hiragino break-all">
                  {getShopByID?.shop?.name}
                </div>
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ショップ説明
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[150px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  placeholder="ショップの説明を入力してください"
                  value={description}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setDescription(event.target.value);
                  }}
                />
                {descriptionError && (
                  <p className="text-red-500">{descriptionError}</p>
                )}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ショップ画像
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                {imagePreview ? (
                  <div className="relative w-32 h-32 mb-4">
                    <img
                      src={imagePreview}
                      alt="Uploaded Preview"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <button
                      onClick={removeImage}
                      className="absolute top-0 right-0 text-white w-5 h-5 flex items-center justify-center"
                    >
                      <span className="material-symbols-rounded text-[20px] text-black hover:cursor-pointer">
                        close
                      </span>
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="dropzone-file"
                    className="w-[106px] h-[24px] pl-[20px] pr-[24px] py-[8px] border-solid border-[1px] border-lightGray rounded-[12px] cursor-pointer bg-#F8FAFC  hover:bg-gray-100"
                  >
                    <div className="flex gap-[2px]">
                      <span className="material-symbols-rounded text-[24px]">
                        upload
                      </span>
                      <div className="text-[16px] text-#111827 font-hiragino font-medium leading-6">
                        画像を追加
                      </div>
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      accept="image/png, image/jpeg"
                      className="hidden"
                      onChange={handleImageUpload}
                    />
                  </label>
                )}
                {imageError && <p className="text-red-500">{imageError}</p>}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    営業時間
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <textarea
                  className="w-full box-border h-[150px] appearance-none border-[1px] border-lightGray border-solid rounded-[12px] py-[16px] px-[18px] text-[16px] text-[#111827] font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText resize-none"
                  id="couponName"
                  placeholder="営業時間を入力してください"
                  value={openingHours}
                  onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                    setOpeningHours(event.target.value);
                  }}
                />
                {openingHoursError && (
                  <p className="text-red-500">{openingHoursError}</p>
                )}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    住所
                  </div>
                  <div className="text-[14px] text-#B91C1C font-hiragino leading-5">
                    必須
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="住所を入力してください"
                  value={address}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setAddress(event.target.value);
                    setHasPushedButtonWithMap(false);
                  }}
                />
                {addressError && <p className="text-red-500">{addressError}</p>}
                <div className="flex flex-col gap-12px">
                  <GoogleMapsPicker
                    latitute={latitude}
                    longitude={longitude}
                    onChangeLocation={({ latitute, longitude }) => {
                      setLatitude(latitute);
                      setLongitude(longitude);
                    }}
                  />
                  <div className="flex gap-[8px] mx-auto">
                    <button
                      type="button"
                      onClick={handleAddressToCoordinates}
                      className="block flex gap-2px w-[212px] h-[38px] py-[8px] px-[10px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
                    >
                      <span className="material-symbols-rounded text-[20px]">
                        add_location
                      </span>
                      <div className="text-14px font-semibold text-#111827 font-hiragino leading-5">
                        住所から地図にピンを刺す
                      </div>
                    </button>
                    <button
                      type="button"
                      onClick={handleCoordinatesToAddress}
                      className="block flex gap-2px w-[240px] h-[38px] py-[8px] px-[10px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
                    >
                      <span className="material-symbols-rounded text-[20px]">
                        explore
                      </span>
                      <div className="text-14px font-semibold text-#111827 font-hiragino leading-5">
                        地図のピンから住所を取得する
                      </div>
                    </button>
                  </div>
                </div>
                {mapError && <p className="text-red-500">{mapError}</p>}
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    お問い合わせ（電話番号）
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="例）01234567890（ハイフンなし）"
                  value={phoneNumber}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setPhoneNumber(event.target.value);
                  }}
                />
              </div>
              <div className="flex flex-col gap-[8px]">
                <label className="block flex gap-[8px]" htmlFor="couponName">
                  <div className="text-[16px] text-#111827 font-hiragino">
                    ホームページURL
                  </div>
                  <div className="text-[14px] text-tertiary font-hiragino leading-5">
                    任意
                  </div>
                </label>
                <input
                  className="w-full box-border py-[16px] px-[18px] rounded-[12px] border-solid border-[1px] border-lightGray text-#111827 font-hiragino focus:outline-none focus:shadow-outline placeholder-disableText"
                  id="couponName"
                  type="text"
                  placeholder="ショップのホームページがある場合は入力してください"
                  value={url}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setUrl(event.target.value);
                  }}
                />
              </div>
            </form>
          </div>
          <div className="mx-auto py-16px">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={!isFormValid()}
              className={`block w-[200px] h-[48px] py-[12px] pl-[28px] pr-[32px] rounded-[12px] ${
                isFormValid()
                  ? "bg-#001CEC text-white hover:bg-#334BFC cursor-pointer"
                  : "bg-#E4E4E7 text-disableText cursor-not-allowed"
              } border-none focus:outline-none`}
            >
              保存する
            </button>
          </div>
        </div>
        <div className="w-[438px] min-h-screen bg-#D1D5DB80">
          <h2 className="m-0 py-24px h-24px border-b-solid border-b-1px border-b-disableText text-center text-18px font-semibold text-#111827 font-hiragino">
            プレビュー
          </h2>
          <div className="flex flex-col gap-24px p-24px w-390px">
            <div className="mx-auto w-144px h-24px py-8px px-16px rounded-9999px bg-lightGray text-18px font-semibold text-secondary font-hiragino leading-6">
              ショップ詳細画面
            </div>
            <div className="flex flex-col gap-12px w-356px p-16px bg-#FFF rounded-12px">
              <div className="text-16px text-#111827 font-hiragino break-all">
                {getShopByID?.shop?.name}
              </div>
              {description && (
                <div className="text-14px text-#303846 font-hiragino">
                  <div
                    className="break-all"
                    dangerouslySetInnerHTML={{
                      __html: description.replace(/\n/g, "<br />"),
                    }}
                  />
                </div>
              )}
              {imagePreview ? (
                <div className="w-full aspect-[4/3] mx-auto rounded-8px overflow-hidden">
                  <img
                    src={imagePreview}
                    alt="Uploaded Preview"
                    className="w-full h-full object-cover"
                  />
                </div>
              ) : (
                <div className="mx-auto w-358px h-139px rounded-15px bg-lightGray text-center">
                  <span className="material-symbols-rounded pt-37px text-[60px] text-#4B556380 rounded-9999">
                    storefront
                  </span>
                </div>
              )}
              {openingHours && (
                <div className="flex flex-col gap-8px">
                  <div className="text-14px font-semibold text-#303846 font-hiragino">
                    営業時間
                  </div>
                  <div className="text-14px text-#303846 font-hiragino">
                    <div
                      className="break-all"
                      dangerouslySetInnerHTML={{
                        __html: openingHours.replace(/\n/g, "<br />"),
                      }}
                    />
                  </div>
                </div>
              )}
              {address && (
                <div className="flex flex-col gap-8px">
                  <div className="text-14px font-semibold text-#303846 font-hiragino">
                    住所
                  </div>
                  <div className="text-14px text-#001EFB font-hiragino break-all">
                    {address}
                  </div>
                </div>
              )}
              <GoogleMapsPicker
                latitute={latitude}
                longitude={longitude}
                onChangeLocation={() => {}}
              />
              {phoneNumber && (
                <div className="flex flex-col gap-8px">
                  <div className="text-14px font-semibold text-#303846 font-hiragino">
                    お問合せ
                  </div>
                  <div className="flex gap-2px w-116px h-20px py-6px pl-8px pr-12px rounded-12px border-solid border-1px border-disableText bg-transparent">
                    <span className="material-symbols-rounded text-[12.75px] text-#374151CC leading-5.5">
                      call
                    </span>
                    <div className="leading-5 text-12px text-secondary font-semibold font-hiragino">
                      {phoneNumber}
                    </div>
                  </div>
                </div>
              )}
              {url && (
                <div className="flex flex-col gap-8px">
                  <div className="text-14px font-semibold text-#303846 font-hiragino">
                    ホームページ
                  </div>
                  <div className="text-14px text-#001EFB font-hiragino break-all">
                    {url}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopEditPage;
